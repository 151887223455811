export default () => {
  return (`
	<div class="attach-item" data-id="{%= o.data.id %}">
	  <input type="hidden" name="{%= o.inputName %}"" value="{%= o.data.id %}">
	  <div class="remove-block">
			<a href="#" class="remove">удалить</a>
	  </div>
	</div>
  `);
};
