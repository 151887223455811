export default () => {
  return (`
	<div class="bar-controls">
	  <div class="text-right">
		  <button type="button" class="btn-smile"><i class="far fa-smile-wink"></i></button>
		  <button type="button" class="btn-attach-manager"><i class="fas fa-paperclip"></i></button>
	  </div>
	</div>
	<div class="attach-photos"></div>
	<div class="attach-audios"></div>
  `);
};
