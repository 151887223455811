const Plugin = require('./plugin');

import templateBarControl from './templates/template-bar-control';
import templateAttachPhotoItem from './templates/template-attach-photo-item';
import templateAttachAudioItem from './templates/template-attach-audio-item';

const tmpl = require("blueimp-tmpl");
const $ = require("jquery");
window.$ = $;
window.jQuery = jQuery;

class BarControlPlugin extends Plugin {
  
  constructor(app, options) {
    super(app, options);
    
	let defaultOpts = {
	  template: null,
	  templateAttachPhotoItem: null,
	  templateAttachAudioItem: null,
	  attachPhotoFiledName: 'attach_photo[]',
	  attachAudioFiledName: 'attach_audio[]'
	};
	this.options = $.extend({} , defaultOpts, options);
	
	this.id = 'BarControl';
	this.type = 'bar-control';
	this.block = 'wgt-bar-control-plugin';
  }
  
  init() {
    if(!this.options.template){
      this.options.template = templateBarControl();
	}
	if(!this.options.templateAttachPhotoItem){
	  this.options.templateAttachPhotoItem = templateAttachPhotoItem();
	}
	if(!this.options.templateAttachAudioItem){
	  this.options.templateAttachAudioItem = templateAttachAudioItem();
	}
  }
  
  addAttachItemPhoto (id, imgPath) {
	let wrap = this.getPluginContainer().find('.attach-photos');
	
	if (this.isNoAttachItem(wrap, id)) {
	  return;
	}
	
	let compileTmpl = tmpl(this.options.templateAttachPhotoItem, {
	  inputName: this.options.attachPhotoFiledName,
	  data: {id: id, path: imgPath}
	});
	wrap.append(compileTmpl);
  }
  
  isNoAttachItem(wrap, id){
	return wrap.find('.attach-item[data-id="' + id + '"]').length > 0;
  }
  
  addAttachItemAudio (id, title) {
	let wrap = this.getPluginContainer().find('.attach-audios');
	if (this.isNoAttachItem(wrap, id)) {
	  return;
	}
	let compileTmpl = tmpl(this.options.templateAttachAudioItem, {
	  inputName: this.options.attachAudioFiledName,
	  data: { id: id }
	});
	wrap.append(compileTmpl);
  }
  
  initEvents () {
	let wrap = this.getPluginContainer();
	
	wrap.off('click', '.btn-attach-manager')
	.on('click', '.btn-attach-manager', (e) => {
	  this.onClickBtnAttachManager(e);
	});
	
	wrap.off('click', '.attach-item .remove')
	.on('click', '.attach-item .remove', (e) => {
	  this.onClickBtnRemoveAttachItem(e);
	});
  }
  
  onClickBtnAttachManager (e) {
	e.preventDefault();
	this.app.hidePluginsByType('attach');
	let plugin = this.app.getPlugin('AttachManager');
	if (plugin.getPluginContainer().is(':hidden')) {
	  plugin.showPlugin();
	} else {
	  plugin.hidePlugin();
	}
  }
  
  onClickBtnRemoveAttachItem (e) {
	e.preventDefault();
	$(e.currentTarget).closest('.attach-item').remove();
  }
  
  render (stage) {
	let plugin = this.createPluginContainer();
	plugin.append(tmpl(this.options.template));
	this.el.find('.wgt-wrap-content').append(plugin);
	this.initEvents();
 
	if(this.options.showAttach === false) {
	  let wrap = this.getPluginContainer().find('.btn-attach-manager').hide();
	}
	
  }
  
}

module.exports = BarControlPlugin;
