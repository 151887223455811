const Plugin = require('./plugin');

class AttachManagerPlugin extends Plugin
{
  
  constructor(app, options) {
    super(app, options);
    
	  this.id = 'AttachManager';
	  this.type = 'attach-manager';
	  this.block = 'wgt-attach-manager-plugin';
  }
  
  onShowPlugin (e) {
	let pluginId = $(e.currentTarget).data('plugin');
	
	this.app.hidePluginsByType('attach');
	this.app.hidePluginsByType(this.type);
	
	let attachPlugin = this.app.getPlugin(pluginId);
	
	attachPlugin.showPlugin();
	attachPlugin.onInitLoadContent();
  }
  
  addItem(title, pluginId) {
	let item = $('<div>', {
	  class: 'attach-item'
	});
	let link = $('<a>', {
	  "href": '#',
	  "data-plugin": pluginId
	}).html(title);
	
	link.on('click', $.proxy(this.onShowPlugin, this));
	item.append(link);
	this.getPluginContainer().append(item);
  }
  
  render(stage) {
	let plugin = this.createPluginContainer();
	this.el.find('.wgt-wrap-content').append(plugin);
	this.hidePlugin();
  }
  
}


module.exports = AttachManagerPlugin;
