export default () => {
  return (`
	  <a class="album-item" data-id="{%= o.data.id %}" href="#">
		  <div class="album-thumb-wrap">
			  <div class="album-thumb fill">
				  <img class="photo" src="{%= o.data.preview %}"></div>
			  <div class="name">{%= o.data.name %}</div>
			  <div class="cnt">{%= o.data.count %}</div>
		  </div>
	  </a>
`);
};
