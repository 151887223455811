const $ = require("jquery");
window.$ = $;
window.jQuery = jQuery;

class Plugin {
  
  constructor(app, options){
    this.app = app;
    this.block = null;
    this.options = options;
  }
  
  init() {}
  
  install() {
	let target = this.options.target;
	this.init();
	this.mount(target, this);
  }
  
  mount(target, plugin) {
	if (!target) {
	  target = this.app.element;
	}
	
	this.el = $(target);
	this.render({});
  }
  
  createPluginContainer () {
	return $('<div>', {class: this.block});
  }
  
  hidePlugin () {
	if (this.el !== undefined && this.block !== undefined) {
	  return this.el.find('.' + this.block).hide();
	}
  }
  
  showPlugin () {
	if (this.el !== undefined && this.block !== undefined) {
	  return this.el.find('.' + this.block).show();
	}
  }
  
  getPluginContainer() {
	if (this.el === undefined || !this.block) {
	  return null;
	}
	return this.el.find('.' + this.block);
  }
  
  render(state) {
	throw (new Error('Extend the render method to add your plugin to a DOM element'));
  }
}

module.exports = Plugin;