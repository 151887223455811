export default () => {
  return (`
	<div>Выберите фотографию</div>
	<div class="photo-items"></div>
	<div class="clear"></div>
	<div>
		<br>
		<a href="#" class="back-to-albums"><i class="fas fa-angle-left"></i> Назад</a>
	</div>
  `);
};
