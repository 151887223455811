export default () => {
  return (`
	<div class="attach-item" data-id="{%= o.data.id %}">
	  <img class="small-thumb photo" src="{%= o.data.path %}">
	  <input type="hidden" name="{%= o.inputName %}" value="{%= o.data.id %}">
	  <div class="remove-block">
		  <a href="#" class="remove">удалить</a>
	  </div>
	</div>
  `);
};
