const $ = require("jquery");
window.$ = $;
window.jQuery = jQuery;

class FormMessageWidget {
  
  constructor(element, options){
	this.element = $(element);
	this.options = options;
	this.plugins = {};
	
	this.element.find('.wgt-wrap-content').empty();
  }
  
  /**
   * init use plugin
   * @param PluginObj
   * @param opts
   * @returns {FormMessageWidget}
   */
  use(PluginObj, opts) {
	if (typeof PluginObj !== 'function') {
	  throw new TypeError('Expected a plugin class, but got ' + (PluginObj === null ? 'null' : typeof PluginObj) + '.');
	}
	
	// Instantiate
	let plugin = new PluginObj(this, opts);
	
	let pluginId = plugin.id;
	this.plugins[plugin.type] = this.plugins[plugin.type] || [];
	
	if (!pluginId) {
	  throw new Error('Your plugin must have an id');
	}
	
	if (!plugin.type) {
	  throw new Error('Your plugin must have a type');
	}
	
	if (!plugin.install) {
	  throw new Error('Your plugin not call method install');
	}
	
	let existsPluginAlready = this.getPlugin(pluginId);
	if (existsPluginAlready) {
	  let msg = 'Already found a plugin named ' + existsPluginAlready.id + ' Tried to use: ' + pluginId + '.\n';
	  throw new Error(msg)
	}
	this.plugins[plugin.type].push(plugin);
	plugin.install();
	return this;
  }
  
  /**
   * hide plugin by type property
   * @param id
   */
  hidePluginsByType (id) {
	for (let i = 0, l = this.plugins[id].length; i < l; i++) {
	  let plugin = this.plugins[id][i];
	  this.element.find('.' + plugin.block).hide();
	}
  }
  
  /**
   * get plugin entity
   * @param pluginId
   * @returns {boolean|*}
   */
  getPlugin(pluginId) {
	for (let group in this.plugins) {
	  for (let i = 0, l = this.plugins[group].length; i < l; i++) {
		let plugin = this.plugins[group][i];
		if (plugin.id === pluginId) {
		  return plugin;
		}
	  }
	}
	return false;
  }
}

module.exports = FormMessageWidget;