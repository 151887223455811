const Plugin = require('./plugin');

import templateSpinner  from './templates/template-spinner.js'
const tmpl = require("blueimp-tmpl");

const $ = require("jquery");
window.$ = $;
window.jQuery = jQuery;

class AttachAudioPlugin extends Plugin {
  
  constructor(app, options = {}) {
	super(app, options);
	
	const defaultOpts = {
	  template: null,
	  endPointUrl: '/wall/attach/audio',
	  
	};
	
	this.options = $.extend({}, defaultOpts, options);
	this.id = 'AttachAudio';
	this.type = 'attach';
	this.block = 'wgt-attach-audio-plugin';
  };
  
  init() {
  
  }
  
  initEvents() {

	
  }
  
  loadAlbums() {
	this.contentSpinner();
	// this.hdx = $.ajax({
	//   url: this.options.endPointUrl,
	//   data: {
	// 	action: 'load-albums'
	//   },
	//   method: 'post',
	//   dataType: 'json',
	//   success: (data) => {
	// 	this.processRenderAlbums(data);
	// 	this.hdx = null;
	//   },
	//   error: (e) => {
	// 	this.hdx = null;
	//   }
	// });
  }
  
  
  stopCurrentAjax() {
	if (this.hdx) {
	  this.hdx.abort();
	  this.hdx = null;
	}
  }
  
  contentClear() {
	this.getPluginContainer().empty();
  }
  
  contentSpinner() {
	this.stopCurrentAjax();
	this.contentClear();
	this.getPluginContainer().append(templateSpinner());
  }
  
  onInitLoadContent() {
	this.loadAlbums();
  }
  
  render(stage) {
	let plugin = this.createPluginContainer();
	let attachManagerPlugin = this.app.getPlugin('AttachManager');
	attachManagerPlugin.addItem('Перекрепить аудио', this.id);
	this.el.find('.wgt-wrap-content').append(plugin);
	this.hidePlugin();
	this.initEvents();
  }
}

module.exports = AttachAudioPlugin;