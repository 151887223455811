const Plugin = require('./plugin');

import templateAlbums from './templates/template-photo-albums';
import templateAlbum from './templates/template-photo-album';
import templateAlbumItem  from './templates/template-photo-album-item';
import templateAlbumPhoto from './templates/template-photo-item';
import templateSpinner  from './templates/template-spinner.js'
const tmpl = require("blueimp-tmpl");

const $ = require("jquery");
window.$ = $;
window.jQuery = jQuery;

class AttachPhotoPlugin extends Plugin {
  
  constructor(app, options = {}) {
	super(app, options);
	
	const defaultOpts = {
	  template: null,
	  endPointUrl: '/wall/attach/photo',
	  templatePhotoItem: null,
	  templateAlbums: null,
	  templateAlbum: null,
	  templateAlbumItem: null
	};
	
	this.options = $.extend({}, defaultOpts, options);
	this.id = 'AttachPhoto';
	this.type = 'attach';
	this.block = 'wgt-attach-photo-plugin';
  };
  
  init() {
    if(!this.options.templateAlbums){
	  this.options.templateAlbums = templateAlbums();
	}
	if(!this.options.templateAlbumItem){
	  this.options.templateAlbumItem = templateAlbumItem();
	}
	if(!this.options.templatePhotoItem){
	  this.options.templatePhotoItem =  templateAlbumPhoto();
	}
	if(!this.options.templateAlbum){
	  this.options.templateAlbum =  templateAlbum();
	}
  }
  
  initEvents() {
	let wrap = this.getPluginContainer();
	wrap.on('click', '.album-item', (e) => {
	  this.onClickAlbumItem(e);
	});
	
	wrap.on('click', '.photo-item', (e) => {
	  this.onClickPhotoItem(e);
	});
	
	wrap.on('click', '.back-to-albums', (e) => {
	  this.onClickBackToAlbums(e);
	});
	
  }
  
  onClickBackToAlbums(e) {
	e.preventDefault();
	this.loadAlbums();
  }
  
  onClickPhotoItem(e) {
	e.preventDefault();
	
	let item = $(e.currentTarget);
	let photoId = item.data('id');
	let photoPath = item.find('img').attr('src');
	
	this.app.getPlugin('BarControl').addAttachItemPhoto(photoId, photoPath);
  }
  
  onClickAlbumItem(e) {
	e.preventDefault();
	
	let albuId = $(e.currentTarget).data('id');
	this.loadAlbumById(albuId);
  }
  
  processRenderAlbum(data) {
	this.contentClear();
	this.getPluginContainer().append(this.options.templateAlbum);
 
	
	let wrap = this.getPluginContainer().find('.photo-items');
	for (let i = 0, l = data.length; i < l; i++) {
	  let album = tmpl(this.options.templatePhotoItem, {
		data: data[i]
	  });
	  wrap.append(album);
	}
	
	console.log(
	  this.options.templateAlbum,
	  this.options.templatePhotoItem
	);
  }
  
  processRenderAlbums(data) {
	this.contentClear();
	this.getPluginContainer().append(this.options.templateAlbums);
	let wrap = this.getPluginContainer().find('.album-items');
	
	for (let i = 0, l = data.length; i < l; i++) {
	  let album = tmpl(this.options.templateAlbumItem, {
		data: data[i]
	  });
	  wrap.append(album);
	}
  }
  
  loadAlbumById(id) {
	let self = this;
	this.contentSpinner();
	this.hdx = $.ajax({
	  url: this.options.endPointUrl,
	  data: {
		action: 'load-album',
		id: id
	  },
	  method: 'post',
	  dataType: 'json',
	  success: (data) => {
		this.processRenderAlbum(data);
		this.hdx = null;
	  },
	  error: (e) => {
		this.hdx = null;
	  }
	});
  }
  
  loadAlbums() {
	this.contentSpinner();
	this.hdx = $.ajax({
	  url: this.options.endPointUrl,
	  data: {
		action: 'load-albums'
	  },
	  method: 'post',
	  dataType: 'json',
	  success: (data) => {
		this.processRenderAlbums(data);
		this.hdx = null;
	  },
	  error: (e) => {
		this.hdx = null;
	  }
	});
  }
  
  stopCurrentAjax() {
	if (this.hdx) {
	  this.hdx.abort();
	  this.hdx = null;
	}
  }
  
  contentClear() {
	this.getPluginContainer().empty();
  }
  
  contentSpinner() {
	this.stopCurrentAjax();
	this.contentClear();
	this.getPluginContainer().append(templateSpinner());
  }
  
  onInitLoadContent() {
	this.loadAlbums();
  }
  
  render(stage) {
	let plugin = this.createPluginContainer();
	let attachManagerPlugin = this.app.getPlugin('AttachManager');
	attachManagerPlugin.addItem('Прекрепить фото', this.id);
	this.el.find('.wgt-wrap-content').append(plugin);
	this.hidePlugin();
	this.initEvents();
  }
}

module.exports = AttachPhotoPlugin;